











































































































































import { defineComponent, ref, watch, onMounted } from "@vue/composition-api";
import {
  Role,
  User,
  UserCreateBody,
  RuleFunction,
  UserUpdateBody,
} from "@/store";
import {
  useNameValidation,
  useEmailValidation,
  useUsernameValidation,
} from "@/composables/validation";
import { USER_ROLES } from "@/store/constants";

interface Props {
  update: boolean;
  isEditable: boolean;
  roles: Array<Role>;
  loading: boolean;
  user?: User;
}

export default defineComponent({
  name: "UserForm",
  props: {
    update: Boolean,
    user: Object,
    roles: Array,
    loading: Boolean,
    isEditable: Boolean,
  },
  setup(props: Props, { emit }) {
    const firstName = ref<string>("");
    const lastName = ref<string>("");
    const email = ref<string>("");
    const username = ref<string>("");
    const role = ref<number | null>(null);
    const mfaEnabled = ref<boolean>(false);
    const isDogTrainer = ref<boolean>(false);
    const cognitoDisabled = ref<boolean | undefined>(false);

    function updateFormFromProps() {
      if (props.update && props.user) {
        firstName.value = props.user.firstName;
        lastName.value = props.user.lastName;
        email.value = props.user.email;
        username.value = props.user.username;
        role.value = props.user.role.id;
        isDogTrainer.value = props.user.isDogTrainer;
        mfaEnabled.value = props.user.mfaEnabled;
        cognitoDisabled.value = props.user.cognitoDisabled;
      }
    }

    onMounted(() => {
      updateFormFromProps();
    });

    watch(
      () => props.loading,
      () => {
        updateFormFromProps();
      }
    );

    const valid = ref<boolean>(false);
    const userForm = ref<{ validate: Function } | null>(null);

    function getUpdates(): UserUpdateBody {
      const body: UserUpdateBody = {};
      if (props.user) {
        if (firstName.value !== props.user.firstName) {
          body.firstName = firstName.value;
        }
        if (lastName.value !== props.user.lastName) {
          body.lastName = lastName.value;
        }
        if (email.value !== props.user.email) {
          body.email = email.value;
        }
        if (role.value !== null && role.value !== props.user.role.id) {
          body.role = role.value;
        }
        if (isDogTrainer.value !== props.user.isDogTrainer) {
          body.isDogTrainer = isDogTrainer.value;
        }
        //TODO MFA Enabled
      }
      return body;
    }

    function changeIsDogTrainerByRole(event: number) {
      if (event === USER_ROLES.DOG_TRAINER) {
        isDogTrainer.value = true;
      }
    }

    function submit() {
      if (valid.value) {
        if (props.update && props.user) {
          const body = getUpdates();
          if (Object.keys(body).length !== 0) {
            emit("update", body);
          } else {
            return;
          }
        } else {
          const body: UserCreateBody = {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            username: username.value,
            role: role.value as number,
            isDogTrainer: isDogTrainer.value,
            cognitoDisabled: cognitoDisabled.value,
          };
          emit("create", body);
        }
      } else {
        if (userForm.value) {
          userForm.value.validate();
        }
      }
    }

    const { emailRules } = useEmailValidation();
    const { usernameRules } = useUsernameValidation();
    const { firstNameRules, lastNameRules } = useNameValidation();

    const roleRules = ref<RuleFunction<number>[]>([
      (value: number) => value !== null || "Role is required",
    ]);

    return {
      firstName,
      lastName,
      email,
      username,
      isDogTrainer,
      cognitoDisabled,
      mfaEnabled,
      role,
      submit,
      valid,
      userForm,
      emailRules,
      usernameRules,
      roleRules,
      firstNameRules,
      lastNameRules,
      getUpdates,
      changeIsDogTrainerByRole,
      updateFormFromProps,
      USER_ROLES,
    };
  },
});
